<template>
    <div class="p-project">
        <div class="container-xl">
            <PageHeader :title="$route.params.clientProjectName" :text="`${personasCount($route.params.clientProjectName)} persona(s)`"></PageHeader>
            <div v-if="personasCount($route.params.clientProjectName) > 0"  class="row">
                <div v-for="persona in personas($route.params.clientProjectName)" :key="persona.id" class="col-lg-4 col-md-6 col-12">
                    <router-link :to="`/share/${$route.params.clientProjectName}/${persona.id}`" class="p-project__link">
                        <Card :title="persona.name" :text="persona.description" :image="`${ persona.thumbnail ? persona.thumbnail : 'https://via.placeholder.com/160x160'}`" />
                    </router-link>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="p-project__no-persona d-flex flex-column align-items-center justify-content-center">
                        <h4>No persona created yet!</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Card from '../components/Card.vue';
    import PageHeader from '../components/PageHeader.vue';

    export default {
        name: 'ClientProject',

        components: {
            Card,
            PageHeader,
        },

        computed: {
            ...mapGetters({
                personasCount: 'personas/countPublishedPersonaByProject',
                personaPublishedByProject: 'personas/personaPublishedByProject',
            }),
            personasTotal() {
                return this.personasCount
            },
            personas() {
                return this.personaPublishedByProject
            }
        },

        methods: {
            ...mapActions('personas', {
                fetchPersonas: 'fetchPersonas'
            }),
        },

        created() {
            this.$store.dispatch('personas/fetchPersonas');

            if(!sessionStorage.getItem('hasAccessToProject')) {
                this.$router.push({ name: 'ClientPassword', params: { clientProjectName: this.$route.params.clientProjectName } })
            }
        },
    }
</script>
